import * as React from "react"

import PickerScreen from '../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="What do you want to create?"
    options={[
      {
        label: "Digital product design",
        caption: "(e.g. mobile apps, web pages, software interfaces)",
        link: "/app/digital-product-design/"
      },
      {
        label: "Graphic design",
        caption: "(e.g. infographics, presentation slides, marketing materials)",
        link: "/app/graphic-design/"
      }
  ]}/>
)

export default Picker;


